import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from './Components/authConfig';

import TermsPopup from './Components/terms.jsx'; // Import the TermsPopup component
const msalInstance = new PublicClientApplication(msalConfig);

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
    <Router>
      <App />
      </Router>
    </MsalProvider>
  </React.StrictMode>
);

reportWebVitals();