import { React, useEffect, useState, Fragment } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import ProductTable from "./productTable";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dialog } from "primereact/dialog";
import "./history.css";
import axios from "axios";

export default function HistoryTab({ token, user, setShowData, setResultsData, SetFunctions, SetOldFunctions, setHistoryDisplayModal}) {
  const [spinnerObj, setSpinnerObj] = useState({});
  const [displayModal, setDisplayModal] = useState(false);
  const [Functions, setLocalFunctions] = useState([]);
  const [historyKeys, setHistoryKeys] = useState([]);

  const renderFooter = (name) => {
    return (
      <div>
        <Button
          className="rounded-button"
          label="Close"
          icon="pi pi-times"
          onClick={() => setDisplayModal(false)}
        />
      </div>
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const historyData = {
      CDSID: user,
    };

    const config = {
      headers: {
        "Authorization": `Bearer ${token}`,
      },
    }
    await axios
      .post(process.env.REACT_APP_FLASK_URL + "/history", historyData, config)
      .then((response) => {
        setHistoryKeys(Object.keys(response.data));
        setLocalFunctions(response.data);
        setSpinnerObj(false);
        // console.log("history",response.data);
        return response.data;
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  // const handleExport = () => {
  //   const feedbackData = {
  //       CDSID: user,
  //       headers: {
  //         "Access-Control-Allow-Origin": "*",
  //         "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  //         "Content-Type": "text/csv"
  //       }
  //     };

  //     axios.post(process.env.REACT_APP_FLASK_URL + '/exportHistory', feedbackData, { responseType: 'blob' })
  //     .then(response => {
  //       var data = new Blob([response.data], {type: 'text/csv'});
  //       var tempLink;
  //       var csvURL = window.URL.createObjectURL(data);
  //       tempLink = document.createElement('a');
  //       tempLink.href = csvURL;
  //       tempLink.setAttribute('download', 'smartFMA.csv');
  //       tempLink.click();
  //   })
  //   .catch(error => {console.error("Error submitting feedback:", error);});

  // };

  const openGroup = async (system) => {
    setDisplayModal(true);
    setShowData(false);
    // console.log("system", system);
    let selectedSystems = [{'name': system}]
    const feedbackData = {
      CDSID: user,
    };  

    const config = {
      headers: {
        "Authorization": `Bearer ${token}`,
      }
    }

    axios.post(process.env.REACT_APP_FLASK_URL + '/endSession', feedbackData, config)
    .then(response => {})
    .then(() => fetch(process.env.REACT_APP_FLASK_URL + "/selection", {
        method: "POST",
        body: JSON.stringify({
          selectedSystems: selectedSystems,
          CDSID: user,
        }),
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => response.json())
      .then((data) => {
          // Handle the response from the endpoint if needed
        setHistoryDisplayModal(false);
        setResultsData(data.Functions);
        SetFunctions(selectedSystems);
        SetOldFunctions(data.data);
        setShowData(true);
      })
      .catch((error) => {
          // Handle any errors that occur during the request
        console.error(error);
      }))
  };

  const accordions = () => {
    return Object.keys(Functions).map((func) => {
      return (
        <Fragment key={func}>
          <h4 onClick={() => openGroup(func)}>{func}</h4>
        </Fragment>
      );
    });
  };

  return (
    <>
      <Dialog
        header="Processing..."
        visible={displayModal}
        style={{ width: "50vw"  }}
        footer={renderFooter("displayBasic")}
        onHide={() => setDisplayModal(false)}
      >
        <p>
          Retrieving Functions from cache
        </p>
        <p>Please wait.</p>
        <ProgressSpinner strokeWidth="8" />
      </Dialog>
      {spinnerObj && <ProgressSpinner strokeWidth="8" />}
      {!spinnerObj && (
        <div className="history-wrapper">
          {historyKeys.length > 0 && accordions()}
          {historyKeys.length === 0 && <h1>No History Found</h1>}
        </div>
      )}
    </>
  );
}
