import React from 'react';
import { useMsal } from "@azure/msal-react";

function Login() {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginPopup().then((response) => {
      
    });
  };

  return <div style={{paddingTop:'8px'}} onClick={handleLogin}> </div>;
}

export default Login;