import {React, useState, useRef} from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import ProductTable from './productTable';
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './results.css';
import axios from 'axios';
import { Toast } from 'primereact/toast';

//const API_URL = "https://flask-app-v3-pnj66h6cza-uc.a.run.app"

export default function Results({toastProp, token, user, sessionStart, resultsData, setShowData,selectedSystems}) {
    let tabs = [];
    const [headerKey, setHeaderKey] = useState('');
    const [spinnerObj, setSpinnerObj] = useState({});
    const [updatedFunctions, setFunctions] = useState([]);
    const [activeIndex, setActiveIndex] = useState(null);
    const Functions = {
        Function: resultsData
    };

    const toast = useRef(null);
    const show = () => {
      toast.current.show({ severity: 'info', summary: 'Info', detail: 'Message Content' });
    };


    const feedbackClick = (e, type, tableType, header) => {
        e.stopPropagation();
        if (type === "thumbsup") {
            toast.current.show({ severity: 'info', summary: 'Excellent!', detail: 'Thanks for the Feedback' });
          }
          if (type === "thumbsdown") {
            toast.current.show({ severity: 'info', summary: 'Sorry!', detail: 'Thanks for the Feedback' });
          }

          const feedbackData = {
            type: type,
            tableType: tableType,
            header: header,
            CDSID: user,
            
          };

          const config = {
            headers: {
                "Authorization": `Bearer ${token}`
            }
          }

      
          axios.post(process.env.REACT_APP_FLASK_URL + "/Feedback", feedbackData, config)
            .then(response => {console.log("Feedback submitted successfully");})
            .catch(error => {console.error("Error submitting feedback:", error);});
        }
      

const createDynamicTabs = () => {
    for (const func in Functions.Function) {
        const funcData = Functions.Function[func];
        const inputs = funcData.inputs.map(input => input);
        const outputs = funcData.outputs.map(output => output);
        const parts = funcData.parts.map(part => ({ parts: part }));
        const selectedinputs = funcData.selectedinputs?.map(input => input);
        const selectedoutputs = funcData.selectedoutputs?.map(output => output);
        tabs = [...tabs, { 
            header: <div className='accordion-header'>
                    <h1>{func}</h1><div className="feedback-icons">
                    <div>
                        <Toast ref={toast} />
                        <Button icon="pi pi-thumbs-up" rounded text onClick={(e) =>  feedbackClick(e, 'thumbsup', "function", func)} aria-label="Filter" />
                        <Button icon="pi pi-thumbs-down" rounded text onClick={(e) => feedbackClick(e, 'thumbsdown', "function", func)} aria-label="Filter" />
                    </div>
                    
                </div>
                </div>, 
            children: <ProductTable token={token} groupName={selectedSystems} Functions={Functions} user={user} sessionStart={sessionStart} spinnerObj={spinnerObj} header={func} outputs={outputs} inputs={inputs} cacheInputs={selectedinputs} cacheOutputs={selectedoutputs} parts={parts}/> 
        }];
    }
    return tabs.map((tab, i) => {
        return (
            <AccordionTab key={tab.header} header={tab.header} disabled={tab.disabled}>
                {tab.children}
            </AccordionTab>
        );
    });
    };


    const onOpen = async (event) => {
    // Perform any actions or logic when a tab is opened
        const headersArray = Object.keys(resultsData);
        const header = headersArray[event.index];
        setSpinnerObj({[header]: false})
        if (!Functions.Function[header].cached) {
        
            setSpinnerObj({[header]: true})

            const [Inresponse, Outresponse] = await Promise.all([
                axios.post(process.env.REACT_APP_FLASK_URL+ '/Generate_INputs', { Funct: header}, {headers: {"Authorization": `Bearer ${token}`} }),
                axios.post(process.env.REACT_APP_FLASK_URL + '/Generate_OUTputs', { Funct: header}, {headers: {"Authorization": `Bearer ${token}`} })
            ]);
            setSpinnerObj({[header]: false})

            const inputs = Inresponse.data;
            const outputs = Outresponse.data;
        
            // Update the inputs and outputs of the selected function
            const updatedFunctions = { ...Functions };
            updatedFunctions.Function[header].inputs = inputs;
            updatedFunctions.Function[header].outputs = outputs;
            updatedFunctions.Function[header]["cached"] = true;

        
            // Update the state with the updated inputs and outputs
            // setSelectedInputs(inputs);
            // setSelectedOutputs(outputs);

            setFunctions(updatedFunctions); 
        }
    }
    const handleClickback = () => {
        setShowData(false);
        const feedbackData = {
            CDSID: user,
            sessionStart: sessionStart.toISOString(),
          };  
      
          const config = {
            headers: {
                "Authorization": `Bearer ${token}`,
              }
          }
          axios.post(process.env.REACT_APP_FLASK_URL + '/endSession', feedbackData, config)
            .then(response => {
          })
      };

      const handleSave = () => {
        const feedbackData = {
            CDSID: user,
            sessionStart: sessionStart.toISOString(),
            groupName: selectedSystems
          };  
      
          const config = {
            headers: {
                "Authorization": `Bearer ${token}`,
              }
          }
          axios.post(process.env.REACT_APP_FLASK_URL + '/saveSession', feedbackData, config)
            .then(response => {
                if(toastProp.current){
                    toastProp.current.show({ severity: 'info', summary: 'Excellent!', detail: response.data.message || "" });
                }
          })
      }
    
    const handleExport = () => {    
        const feedbackData = {
            CDSID: user,
          };  

          axios.post(process.env.REACT_APP_FLASK_URL + '/export', feedbackData, { responseType: 'blob', headers: {
            "Authorization": `Bearer ${token}`,
          }})
            .then(response => {
                var data = new Blob([response.data], {type: 'text/csv'});
                var tempLink;
                var csvURL = window.URL.createObjectURL(data);
                tempLink = document.createElement('a');
                tempLink.href = csvURL;
                tempLink.setAttribute('download', 'smartFMA.csv');
                tempLink.click();
            })
            .catch(error => {console.error("Error submitting feedback:", error);});
    }

    return (
        <div className='results-wrapper'>
            <div className='backButton'>
            <Button className='rounded-button' onClick={handleClickback}>Back</Button>
                <Button className='rounded-button' onClick={handleSave}>Save</Button>
            </div>
            
            <Accordion multiple activeIndex={activeIndex} onTabOpen={(event) => onOpen(event)}>{createDynamicTabs()}</Accordion>
            
            <div className='exportButton'>
                <Button className='rounded-button' onClick={() => handleExport()}>Export Selected</Button>
            </div>
            
            

        </div>
    );
};
