import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import Profile from './Profile';
//import { Route } from "react-router-dom";

function ProtectedRoute() {
  const authRequest = {
    scopes: ["User.Read"]
  };

  return (
    <MsalAuthenticationTemplate interactionType="redirect" authenticationRequest={authRequest}>
    <Routes>
        <Route path="/profile" element={<Profile />} />
    </Routes>
    </MsalAuthenticationTemplate>
  );
}

export default ProtectedRoute;
