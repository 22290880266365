import './App.css';
import { useState, useRef, useEffect, createRef } from 'react';
import Filter from './Components/filter';
import Welcome from './Components/Welcome';
import Results from './Components/results';
import Menu from './Components/Menu';
import { Button } from 'primereact/button';
import 'primereact/resources/themes/saga-blue/theme.css';
import './style.css';
import FEDEDATA from './Components/FEDETableData';
import { getTokenFromCloudFunction } from './getToken';
import { Toast } from 'primereact/toast';

import { useMsal, useAccount, MsalProvider, useIsAuthenticated } from "@azure/msal-react";
import Login from './Components/Login';
import Profile from './Components/Profile';
import ProtectedRoute from './Components/ProtectedRoute';
import { PublicClientApplication } from "@azure/msal-browser";
//import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./Components/authConfig";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { useIdleTimer } from 'react-idle-timer';
import axios from 'axios';
import TermsPopup from './Components/terms';
import {InteractionStatus} from '@azure/msal-browser';
//const msalInstance = new PublicClientApplication(msalConfig);
function App() {
  const { accounts } = useMsal();
  const idleTimerRef=useRef(null);
  const toast = createRef(null);
  const account = useAccount(accounts && accounts[0] ? accounts[0] : {}); 
  const [data, setData] = useState([{}]);
  const [showData, setShowData] = useState(false);
  const [resultsData, setResultsData] = useState()
  const [Functions, SetFunctions] = useState([]);
  const [oldFunctions, SetOldFunctions] = useState([]);
  const [startSession, setStartSession] = useState(new Date());
  const [endSession, setEndSession] = useState(false);
  const [token, setToken] = useState();
  const isAuthenticated = useIsAuthenticated();


  const handleClick = () => {
    setShowData(true);
  };

  const onIdle=()=>{
    const feedbackData = {
      CDSID: account.username.split('@')[0],
      sessionStart: startSession.toISOString(),
    };  

    const config = {
      headers: {
        "Authorization": `Bearer ${token}`,
      }
  }

    axios.post(process.env.REACT_APP_FLASK_URL + '/endSession', feedbackData, config)
      .then(response => {
    })
    .catch(error => console.log(error));

    setEndSession(true)
  }

  useEffect(() => {
    getTokenFromCloudFunction().then(setToken);
  }, []);




  useEffect(() => {
    if(account && token){
      const feedbackData = {
        CDSID: account.username.split('@')[0],
        sessionStart: startSession.toISOString(),
      };

      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
        }
      }

      const interval = setInterval(() => {
        axios.post(process.env.REACT_APP_FLASK_URL + '/heartbeat', feedbackData, config)
        .then((response) => {
        })
        .catch((error) => {
            console.log(error);
        });
      }, 10 * 1000); 

      // Cleanup function to clear the interval when the component unmounts
      return () => clearInterval(interval);
    }
  }, [token]);

  const onActive = () =>{
    setStartSession(new Date());
  }

  const idleTimer = useIdleTimer({
    crossTab: true,
    ref: idleTimerRef,
    onIdle: onIdle,
    timeout: 30*60*1000,
    // timeout: 1*10*1000,
    onActive: onActive
  })
  //const USER = "JBEAGLE2";

  
  // useEffect(() => {
  //   if (!account && instance) {
  //     instance.loginRedirect();
  //   }
  // }, [account, instance]);

  if(token){
    return (
      <div className="App" idletimer ={idleTimer }> 
      <Toast ref={toast} />
      {isAuthenticated && <TermsPopup isAuthenticated={isAuthenticated} />}
      <Menu token={token} user={account ? account.name : ""} CDSID={account && account.username ? account.username.split('@')[0] : ""} sessionStart={startSession} setShowData={setShowData} setResultsData={setResultsData} SetFunctions={SetFunctions} SetOldFunctions={SetOldFunctions}/>
        {account ? <Profile /> : <Login />}
    
        <header className="App-header">
        <ProtectedRoute /> 
          {/* <img src={logo} className="App-logo" alt="logo" /> */}
          {showData ? (
            <div>
              <div className='listresults'>
                <p>SmartFMA Results for:</p> 
                {/* <p>{JSON.stringify(Functions)}</p> */}
                {Functions.map((item, index) => (
                  <p key={index}>{item.name}</p>
                ))}
                </div> 
            <Results toastProp={toast} token={token} user={account.username.split('@')[0]} sessionStart={startSession} resultsData={resultsData} selectedSystems={Functions} setShowData={setShowData} oldFunctions ={oldFunctions}/>
            
            <div className='OldData'><FEDEDATA oldFunctions ={oldFunctions}/></div>

            
            </div>
          ) : (
            <div>
              <Welcome />

              <Filter token={token} sessionStart={startSession} setShowData={setShowData} setResultsData={setResultsData} SetFunctions={SetFunctions} SetOldFunctions={SetOldFunctions} user={account && account.username ? account.username.split('@')[0] : ""}/>
              
            </div>
          )}
        </header>
      </div>
      
    );
  }
}

export default App;