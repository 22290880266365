import { React, useState, useRef, useEffect  } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button"
import { ProgressSpinner } from 'primereact/progressspinner';
import 'primeicons/primeicons.css';
import "./productTable.css";
import { Toast } from 'primereact/toast';
import axios from 'axios';

export default function ProductTable({ token, Functions, groupName, cacheInputs, cacheOutputs, user, spinnerObj, header, parts, inputs, outputs }) {
  const toast = useRef(null);
  const [selectedInputs, setSelectedInputs] = useState(cacheInputs || []);
  const [selectedOutputs, setSelectedOutputs] = useState(cacheOutputs || []);
  const inputObj = inputs.map((input) => ({inputs: input}));
  const outputObj = outputs.map((output) => ({outputs: output}));
  const show = () => {
    toast.current.show({ severity: 'info', summary: 'Info', detail: 'Message Content' });
  };


  useEffect(() => { 
      storeInRedis();
 }, [selectedInputs, selectedOutputs, inputs, outputs])

const handleCheckboxChange = (selectedItem, checkType) => {
  let newSelectedInputs = [];
  let newSelectedOutputs = [];

  if(checkType === 'input'){
    const exists = selectedInputs.some(item => item === selectedItem.inputs);

    if (!exists) {
      newSelectedInputs = [...selectedInputs, selectedItem.inputs];
    } else {
      newSelectedInputs = selectedInputs.filter(item => item !== selectedItem.inputs);
    }

    setSelectedInputs(newSelectedInputs);
  } else if(checkType === 'output'){
    const exists = selectedOutputs.some(item => item === selectedItem.outputs);

    if (!exists) {
      newSelectedOutputs = [...selectedOutputs, selectedItem.outputs];
    } else {
      newSelectedOutputs = selectedOutputs.filter(item => item !== selectedItem.outputs);
    }

    setSelectedOutputs(newSelectedOutputs);
  }
};

  const storeInRedis = () =>{
    Functions.Function[header]["selectedinputs"] = selectedInputs;
    Functions.Function[header]["selectedoutputs"] = selectedOutputs;

    const checkData = {
      Functions: Functions.Function,
      CDSID: user,
      groupName: groupName,
    };

    const config = {
      headers: {
        "Authorization": `Bearer ${token}`,
      }
    }

    axios.post(process.env.REACT_APP_FLASK_URL + '/check', checkData, config)
      .then(response => {})
      .catch(error => {console.error("Error submitting feedback:", () => error);});
  }

  const onChecked = (rowData) =>{
   
  }

  // const feedbackClick = (type, tableType) => {
  //   if (type === "thumbsup") {
  //     toast.current.show({ severity: 'info', summary: 'Excellent!', detail: 'Thanks for the Feedback' });
  //   }
  //   if (type === "thumbsdown") {
  //     toast.current.show({ severity: 'info', summary: 'Sorry!', detail: 'Thanks for the Feedback' });
  //   }
    
  //   const feedbackData = {
  //     type: type,
  //     tableType: tableType,
  //     header: header,
  //     CDSID: user,
  //     headers: {
  //       "Authorization": `Bearer ${token}`,
  //     }
  //   };

  //   axios.post("api/Feedback", feedbackData)
  //     .then(response => {console.log("Feedback submitted successfully");})
  //     .catch(error => {console.error("Error submitting feedback:", () => error);});
  // }

  const feedbackClick = (type, tableType) => {
    if (type === "thumbsup") {
      toast.current.show({ severity: 'info', summary: 'Excellent!', detail: 'Thanks for the Feedback' });
    }
    if (type === "thumbsdown") {
      toast.current.show({ severity: 'info', summary: 'Sorry!', detail: 'Thanks for the Feedback' });
    }

      const feedbackData = {
        type: type,
        tableType: tableType,
        header: header,
        CDSID: user,
        
      };

      const config = {
        headers: {
            "Authorization": `Bearer ${token}`
        }
      }

  
      axios.post(process.env.REACT_APP_FLASK_URL + "/Feedback", feedbackData, config)
        .then(response => {console.log("Feedback submitted successfully");})
        .catch(error => {console.error("Error submitting feedback:", error);});
    }

  return (
    <>
      { spinnerObj[header] && <ProgressSpinner strokeWidth="8" /> }
      {!spinnerObj[header] && <div className="table-wrapper">
        <div className="product-wrapper">
          <DataTable
            className="part-wrapper"
            value={parts}
            tableStyle={{ minWidth: "50rem" }}
          >
            <Column field="parts" header="Parts"></Column>
          </DataTable>
        </div>

        <div className="product-wrapper">
          <DataTable
            className="part-wrapper"
            dataKey="input-id"
            value={inputObj}
            tableStyle={{ minWidth: "50rem" }}
          >
            <Column
              body={(rowData) => (
                <input
                  type="checkbox"
                  checked={selectedInputs?.some(item => item === rowData.inputs)}
                  onChange={() => handleCheckboxChange(rowData, 'input')}
                />
              )}
            ></Column>
            <Column field="inputs" header="Inputs"></Column>
          </DataTable>
          <div className="feedback-icons">
            <Toast ref={toast} />
            <Button icon="pi pi-thumbs-up" rounded text onClick={(e) =>  feedbackClick('thumbsup', "input")} aria-label="Filter" />
            <Button icon="pi pi-thumbs-down" rounded text onClick={() => feedbackClick('thumbsdown', "inputs")} aria-label="Filter" />
          </div>
        </div>

        <div className="product-wrapper">
          <DataTable
            className="part-wrapper"
            dataKey="output-id"
            value={outputObj}
            tableStyle={{ minWidth: "50rem" }}
          >
            <Column
              body={(rowData) => (
                <input
                  type="checkbox"
                  checked={selectedOutputs?.some(item => item === rowData.outputs)}
                  onChange={() => handleCheckboxChange(rowData, 'output')}
                />
              )}
            ></Column>
            <Column field="outputs" header="Outputs"></Column>
          </DataTable>
          <div className="feedback-icons">
          <Toast ref={toast} />
            <Button icon="pi pi-thumbs-up" rounded text onClick={(e) =>  feedbackClick('thumbsup', "outputs")} aria-label="Filter" />
            <Button icon="pi pi-thumbs-down" rounded text onClick={() => feedbackClick('thumbsdown', "outputs")} aria-label="Filter" />
          </div>
        </div>
      </div>
      }
    </>
  );
}
