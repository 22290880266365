// import React, { useState, useEffect } from 'react';
// import { DataTable } from 'primereact/datatable';
// import { Column } from 'primereact/column';
// import { Accordion, AccordionTab } from 'primereact/accordion';

// export default function SingleColumnDemo(oldFunctions) {

//     return (
//         <div className="OldDataCard" style={{  width: '100%'}}>
//             {/* Look at https://primereact.org/datatable/#basic_filter */}
//             <Accordion >
//                 <AccordionTab header="FEDE Data for Selected Systems">
//                 <DataTable value={oldFunctions.oldFunctions}  sortField="Function" sortOrder={-1} stripedRows showGridlines tableStyle={{minWidth: '50rem' }}>
//                     <Column field="Component" header="System" sortable style={{ width:'10ch' }}></Column>
//                     <Column field="Input" header="Input" sortable style={{ width: '10ch' }}></Column>
//                     <Column field="Function" header="Function" sortable style={{ width: '10ch' }}></Column>
//                     <Column field="Output" header="Output" sortable style={{ width: '25ch' }}></Column>
//                 </DataTable>
//                 </AccordionTab>
//             </Accordion>
            
            
           
//         </div>
//     );
// }


import React, { useState, useEffect } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Accordion, AccordionTab } from 'primereact/accordion';


export default function BasicFilterDemo(oldFunctions) {
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        // name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        // 'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        // representative: { value: null, matchMode: FilterMatchMode.IN },
        // status: { value: null, matchMode: FilterMatchMode.EQUALS },
        // verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });

    const [globalFilterValue, setGlobalFilterValue] = useState('');


    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-end" style={{display: 'flex', flexDirection: 'row-reverse',    justifycontent: 'center'}}>
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </IconField>
            </div>
        );
    };


    const header = renderHeader();

    return (
        <div className="card">
            <Accordion >
                <AccordionTab header="FEDE Data for Selected Systems">
                    <DataTable value={oldFunctions.oldFunctions} paginator rows={10}  filters={filters}
                        globalFilterFields={['Input', 'Function', 'Output']} header={header} emptyMessage="No data found." tableStyle={{minWidth: '90rem' }}>
                        <Column field="Component" header="System" sortable style={{ width:'10ch' }}></Column>
                        <Column field="Input" header="Input" sortable style={{ width: '10ch' }}></Column>
                        <Column field="Function" header="Function" sortable style={{ width: '10ch' }}></Column>
                        <Column field="Output" header="Output" sortable style={{ width: '25ch' }}></Column>
                    </DataTable>
                </AccordionTab>
            </Accordion>
        </div>
    );
}