import React, { useState } from 'react'; 
import { useRef } from 'react';
import { Menubar } from 'primereact/menubar';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { SlideMenu } from 'primereact/slidemenu';
import PDlogo from "./Picture3.png"
import AdminCharts from './AdminCharts';
import HistoryTab from './history';
import './Menu.css';
// import { setShowData} from './App';
import logo from './ford_logo.svg';
import axios from 'axios';

export default function Menu({token, user, CDSID, sessionStart, setShowData, setResultsData, SetFunctions, SetOldFunctions}) {
    const [displayModal, setDisplayModal] = useState(false);
    const [displayAdminModal, setAdminDisplayModal] = useState(false);
    const [displayHistoryModal, setHistoryDisplayModal] = useState(false);
    let items;
    const users = ["JBEAGLE2", "MROGER11", "TESTACCOUNT1"];
    if (users.includes(user)) {
        items = [
            {
                label: 'Home',
                icon: 'pi pi-home',
                command: () => { 
                    window.location.reload() 
                    const feedbackData = {
                        CDSID: CDSID,
                        sessionStart: sessionStart.toISOString(),
                        
                      };  

                      const config = {
                        headers: {
                            "Authorization": `Bearer ${token}`,
                          }
                      }
                  
                      axios.post(process.env.REACT_APP_FLASK_URL + '/endSession', feedbackData, config)
                        .then(response => {
                      })

                }
            },
            // {
            //     label: 'Features',
            //     icon: 'pi pi-star',
            //     command: () => {setDisplayModal(true)}
            // },
            {
                label: 'History',
                icon: 'pi pi-history',
                command: () => {
                    setHistoryDisplayModal(true)
                    const feedbackData = {
                        CDSID: CDSID,
                        sessionStart: sessionStart.toISOString(),
                        
                      };  

                      const config = {
                        headers: {
                            "Authorization": `Bearer ${token}`,
                          }
                      }
                  
                      axios.post(process.env.REACT_APP_FLASK_URL + '/endSession', feedbackData, config)
                        .then(response => {
                      })
                }
            }, 
            {
                label: 'Admin',
                icon: 'pi pi-cog',
                command: () => {setAdminDisplayModal(true)}
            },
            // ... rest of your items
            {
                label: 'SmartFMA Webex Community',
                icon: 'pi pi-comments', // choose an appropriate icon
                command: () => {
                    window.open('https://www.webexteams.ford.com/space?r=81lt', '_blank'); // replace with your WebexSpace URL
                }
            }
        ];
    } else {
        items = [
            {
                label: 'Home',
                icon: 'pi pi-home',
                command: () => { 
                    window.location.reload()

                    const feedbackData = {
                        CDSID: CDSID,
                        sessionStart: sessionStart.toISOString(),
                      }; 
                      
                      const config = {
                        headers: {
                            "Authorization": `Bearer ${token}`,
                          }
                      }
                  
                      axios.post(process.env.REACT_APP_FLASK_URL + '/endSession', feedbackData, config)
                        .then(response => {
                      })
                 }
            }, 
            // {
            //     label: 'Features',
            //     icon: 'pi pi-star',
            //     command: () => {setDisplayModal(true)}
            // },
            {
                label: 'History',
                icon: 'pi pi-history',
                command: () => {
                    setHistoryDisplayModal(true)
                    const feedbackData = {
                        CDSID: CDSID,
                        sessionStart: sessionStart.toISOString(),
                      };
                      
                      const config = {
                        headers: {
                            "Authorization": `Bearer ${token}`,
                          }
                      }
                  
                      axios.post(process.env.REACT_APP_FLASK_URL + '/endSession', feedbackData, config)
                        .then(response => {
                      })
                    }
            },
            {
                label: 'SmartFMA Webex Community',
                icon: 'pi pi-comments', // choose an appropriate icon
                command: () => {
                    window.open('https://www.webexteams.ford.com/space?r=81lt', '_blank'); // replace with your WebexSpace URL
                }
            }
            // ... rest of your items for non-matching users
        ];
    }
    

    const end = <div className='Titlelogo'><h1 className='Title' style={{ textAlign: 'center', paddingRight:'30px' }}>SmartFMA </h1><img src={logo} className="App-logo" alt="logo" /></div>;

   

    

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Close" icon="pi pi-times" onClick={() => {setDisplayModal(false); setAdminDisplayModal(false); setHistoryDisplayModal(false)}} />
            </div>
        );
    }


    return (
        <div>
            <Menubar model={items} end={end} />

            <Dialog header="Features" visible={displayModal} style={{ width: '50vw' }} footer={renderFooter('displayBasic')} onHide={() => setDisplayModal(false)}>
                <p>Here are some features...</p>
            </Dialog>

            <Dialog header="History" visible={displayHistoryModal} style={{ width: '95vw' }} onHide={() => setHistoryDisplayModal(false)}>
                <HistoryTab token={token} user={CDSID} setShowData={setShowData} setResultsData={setResultsData} SetFunctions={SetFunctions} SetOldFunctions={SetOldFunctions} setHistoryDisplayModal={setHistoryDisplayModal}/>
            </Dialog>


            <Dialog header="Metrics" visible={displayAdminModal} style={{ width: '90vw', height: '90vh'}} footer={renderFooter('displayBasic')} onHide={() => setAdminDisplayModal(false)}>
                <AdminCharts />
            </Dialog>
        <div className="card" style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row'}}>
                
                {/* <h1 style={{ textAlign: 'center' }}>SmartFMA</h1> */}
            </div>
        </div>
    )
}